import React from 'react'

export class CartItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            }
        // this.props.Item = this.props.Item.data();
        
    }

    async componentDidMount() {
        // console.log(this.props.Item)
        
    }

    
    
    
    render() {
        return (  
            <div className='CartItem'>
                <table className='CartItem'>
                    <tbody>
                        <tr>
                            <td>
                                <img className='ItemImg' src={this.props.Item.ItemImg}/>
                                <br/>
                                <label>{this.props.Item.Name}</label>
                            </td>
                            <td>&nbsp;</td>                        
                            <td>
                                <label>US$ {(this.props.Item.Price*(1-this.props.Item.Discount)).toFixed(2)}</label> {/*  */}
                            </td>
                        </tr>  
                    </tbody>                  
                </table>
                <br/>
            </div>
        )
    }
}


