import React from 'react'

export const DataDeletion = () => {
    return (
        <div className='lefttext'>
            
            <h2>DATA DELETION INSTRUCTIONS</h2> <br/><br/>
            Richer Simulations does not store your personal data; it is used only for login. According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete your activities for Richer Simulations, follow these instructions: <br/>
            <br/>
            Go to Your Facebook Account’s Setting & Privacy. Click ” Setting “.<br/>
            Then, go to ” Apps and Websites” and you will see all of your Apps activities.<br/>
            Select the option box for Richer Simulations.<br/>
            Click ” Remove” button.<br/>
            
        </div>
    )
}
